import "./src/scss/global.scss";
import React from "react";

export const onRouteUpdate = () => {
  const getCookieValue = (name) =>
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";
  const apfmCookie = getCookieValue("apfm");
  if (
    (apfmCookie && apfmCookie == "true") ||
    window.location.pathname == "/aplaceformom"
  ) {
    var links = document.getElementsByTagName("a");
    for (var i = 0; i < links.length; i++) {
      if (links[i].getAttribute("href").includes("#/auth/sign-up")) {
        links[i].addEventListener('click', function(e) {
          e.preventDefault();
          // Setting new window before fetch to prevent popupblockers from firing...
          var win = window.open('checkout', '_blank');
          fetch("https://my.everplans.com/api/v2/stripe-checkouts", {
            method: "POST",
            async: false,
            body: JSON.stringify({
              data: {
                type: "stripe-checkouts",
                attributes: {
                  "partner-code": "d8991b3d81f7d796",
                },
              },
            }),
            headers: {
              "Content-type": "application/vnd.api+json",
            },
          })
            .then((response) => response.json())
            .then((json) => {
              if (json?.data?.attributes?.url) {
                win.location.href = json.data.attributes.url
              }
            });
        });
      }
    }
  }
};
