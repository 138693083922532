export default {
  fonts: {
    primary: "poppins, sans-serif;",
  },
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  fontSizes: {
    body: "1.2rem",
  },
  lineHeights: {
    body: "1.375rem",
    heading: "1.2",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  colors: {
    background: "#fff",
    primary: "#14158B",
    primaryDark: "#5B5DFA",
    gray: "#8e8d8f",
    textGray: "#262626",
    grayBG: "#EDF2FF",
  },
  breakpoints: ["800px", "1000px", "1234px"],
  buttons: {
    primaryBlue: {
      fontSize: "0.875rem",
      borderRadius: "1.5rem",
      color: "white",
      bg: "primary",
      textDecoration: "none",
      border: "thin solid white",
      cursor: "pointer",
      lineHeight: "1.5rem",
      display: "inline-block",
      padding: "0.7rem 2.5rem",
      lineHeight: "1.5rem",
      fontWeight:"300 !important",
      ":hover": {
        bg: "primaryDark",
      },
    },
    primaryWhite: {
      fontSize: "0.875rem",
      borderRadius: "1.5rem",
      color: "primary",
      bg: "white",
      padding: ".8rem 3rem",
      textDecoration: "none",
      cursor: "pointer",
      border: "thin solid",
      borderColor: "primary",
      display: "inline-block",
      padding: "0.7rem 2.5rem",
      lineHeight: "1.5rem",
      fontWeight:"300 !important",
      ":hover": {
        bg: "primaryDark",
        color: "white",
      },
    },
  },
};
